const crypto = require('crypto') // tot sign our pre-signed URL
const v4 = require('./aws-signature-v4') // to generate our pre-signed URL
const mic = require('microphone-stream') // collect microphone input as a stream of raw bytes
const { downsampleBuffer, pcmEncode } = require('./audioUtils') // for encoding audio data as PCM
const { EventStreamCodec } = require('@aws-sdk/eventstream-codec')
const { toUtf8, fromUtf8 } = require('@aws-sdk/util-utf8') // utilities for encoding and decoding UTF8

// our converter between binary event streams messages and JSON

class AWSTranscribe {
    constructor(language, region, accessKey, secretKey, sessionToken, sampleRate, specialty, type) {
        this.language = language
        this.region = region
        this.accessKey = accessKey
        this.secretKey = secretKey
        this.sessionToken = sessionToken
        this.sampleRate = sampleRate
        this.specialty = specialty
        this.type = type
        this.eventStreamMarshaller = new EventStreamCodec(toUtf8, fromUtf8)

        this.inputSampleRate = null
        this.socket = null
    }

    start() {
        window.navigator.mediaDevices
            .getUserMedia({
                video: false,
                audio: true,
            })
            .then(this.streamAudioToWebSocket.bind(this))
            .catch((error) => {
                this.showError(error)
            })
    }

    stop() {
        if (this.socket.readyState === this.socket.OPEN) {
            this.micStream.stop()

            // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts

            let emptyMessage = this.getAudioEventMessage(Buffer.from(new Buffer([])), this.inputSampleRate)

            let emptyBuffer = this.eventStreamMarshaller.encode(emptyMessage)

            this.socket.send(emptyBuffer)

            this.socket.close()
        }
    }

    streamAudioToWebSocket(userMediaStream) {
        this.micStream = new mic()

        this.micStream.on('format', (data) => {
            this.inputSampleRate = data.sampleRate
        })

        this.micStream.setStream(userMediaStream)

        // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
        // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
        let url = this.createPresignedUrl()

        //open up our WebSocket connection
        this.socket = new WebSocket(url)
        this.socket.binaryType = 'arraybuffer'

        // when we get audio data from the mic, send it to the WebSocket if possible
        this.socket.onopen = () => {
            this.micStream.on('data', (rawAudioChunk) => {
                // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
                let binary = this.convertAudioToBinaryMessage(rawAudioChunk)

                if (this.socket.readyState === this.socket.OPEN) this.socket.send(binary)
            })
        }

        // handle messages, errors, and close events
        this.wireSocketEvents()
    }

    wireSocketEvents() {
        let transcribeException
        this.socket.onmessage = (message) => {
            //convert the binary event stream message to JSON
            let messageWrapper = this.eventStreamMarshaller.decode(Buffer(message.data))
            let messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body))

            if (messageWrapper.headers[':message-type'].value === 'event') {
                this.handleEventStreamMessage(messageBody)
            } else {
                transcribeException = true
                this.showError(messageBody.Message)
            }
        }

        let socketError

        this.socket.onerror = () => {
            socketError = true
            this.showError('WebSocket connection error. Try again.')
        }

        this.socket.onclose = (closeEvent) => {
            this.micStream.stop()

            // the close event immediately follows the error event; only handle one.
            if (!socketError && !transcribeException) {
                if (closeEvent.code === 1005) {
                    console.log('Streaming stopped')
                } else {
                    this.showError(closeEvent.reason)
                }
            }
        }
    }

    handleEventStreamMessage(messageJson) {
        let results = messageJson.Transcript.Results

        if (results.length > 0) {
            if (results[0].Alternatives.length > 0) {
                let transcript = results[0].Alternatives[0].Transcript

                // fix encoding for accented characters
                transcript = decodeURIComponent(escape(transcript))

                // // create a new custom event with the transcript data
                const transcriptEvent = new CustomEvent('transcript', {
                    detail: {
                        transcript: transcript,
                        isFinal: !results[0].IsPartial,
                    },
                })

                // dispatch the custom event
                document.dispatchEvent(transcriptEvent)
            }
        }
    }

    showError(message) {
        const errorEvent = new CustomEvent('transcript-error', {
            detail: {
                error: message,
            },
        })

        // dispatch the custom event
        document.dispatchEvent(errorEvent)
    }

    createPresignedUrl() {
        let endpoint = 'transcribestreaming.' + this.region + '.amazonaws.com:8443'
        // get a preauthenticated URL that we can use to establish our WebSocket
        return v4.createPresignedURL(
            'GET',
            endpoint,
            '/medical-stream-transcription-websocket',
            'transcribe',
            crypto.createHash('sha256').update('', 'utf8').digest('hex'),
            {
                key: this.accessKey,
                secret: this.secretKey,
                sessionToken: this.sessionToken,
                protocol: 'wss',
                expires: 15,
                region: this.region,
                query:
                    'language-code=' +
                    this.language +
                    '&media-encoding=pcm&sample-rate=' +
                    this.sampleRate +
                    '&specialty=' +
                    this.specialty +
                    '&type=' +
                    this.type,
            }
        )
    }

    convertAudioToBinaryMessage(audioChunk) {
        let raw = mic.toRaw(audioChunk)

        if (raw == null) return

        // downsample and convert the raw audio bytes to PCM
        let downsampledBuffer = downsampleBuffer(raw, this.inputSampleRate, this.sampleRate)
        let pcmEncodedBuffer = pcmEncode(downsampledBuffer)

        // add the right JSON headers and structure to the message
        let audioEventMessage = this.getAudioEventMessage(Buffer.from(pcmEncodedBuffer))

        return this.eventStreamMarshaller.encode(audioEventMessage)
    }

    getAudioEventMessage(buffer) {
        // wrap the audio data in a JSON envelope
        return {
            headers: {
                ':message-type': {
                    type: 'string',
                    value: 'event',
                },
                ':event-type': {
                    type: 'string',
                    value: 'AudioEvent',
                },
            },
            body: buffer,
        }
    }
}

window.AWSTranscribe = AWSTranscribe
